import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import useDimensions from 'react-cool-dimensions';
import styles from './QRCodeSizeable.module.scss';

const QRCodeSizeable = (props) => {
  const { value } = props;
  const [size, setSize] = useState(0);
  const { observe } = useDimensions({
    onResize: ({ height }) => {
      setSize(height);
    },
  });
  return (
    <div ref={observe} className={styles.main}>
      <QRCode value={value} size={size} />
    </div>
  );
};
export default QRCodeSizeable;
